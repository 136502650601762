import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password_field"];

  toggle_password(e) {
    const password_field = this.password_fieldTarget;
    const type = password_field.type;

    if (type === "password") {
      password_field.type = "text";
    } else {
      password_field.type = "password";
    }
  }
}
