import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'customerId', 'sessionId', 'eventId']

    connect() {
        console.log('connecting event_controller .....')
    }

    filter_reservation() {
        const sessionId = this.sessionIdTarget.value
        const customerId = this.customerIdTarget.value
        const eventId = this.eventIdTarget.value

        fetch(`/events/${eventId}?session_id=${sessionId}&customer_id=${customerId}`, {
            method: "GET",
            headers: { Accept: "text/vnd.turbo-stream.html" }
        })
            .then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html);
            })
            .catch(error => console.error('Erreur:', error));
    }

}