// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'jquery'
import $ from 'jquery';
global.$ = jQuery;


require("@rails/activestorage").start()
require("channels")


import "@hotwired/turbo-rails";
// window.Turbo = Turbo

require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import '../stylesheets/app.scss'

import "controllers"

import 'popper.js'
import 'bootstrap'
import 'trix';

import 'metismenu/dist/metisMenu'
import 'select2/dist/js/select2'

import groupTypeMod from '../modules/group_types'

// Configuration globale pour les requêtes AJAX
$(document).ajaxSend((event, xhr, settings) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    xhr.setRequestHeader('X-CSRF-Token', csrfToken);
});

$.fn.extend({
    treed: function (o) {

        var openedClass = 'mdi mdi-minus-circle-outline';
        var closedClass = 'mdi mdi-plus-circle-outline';

        if (typeof o != 'undefined'){
            if (typeof o.openedClass != 'undefined'){
                openedClass = o.openedClass;
            }
            if (typeof o.closedClass != 'undefined'){
                closedClass = o.closedClass;
            }
        };

        //initialize each of the top levels
        var tree = $(this);
        tree.addClass("tree");
        tree.find('li').has("ul").each(function () {
            var branch = $(this); //li with children ul
            branch.prepend("<i class='indicator glyphicon " + closedClass + "'></i>");
            branch.addClass('branch');
            branch.on('click', function (e) {
                if (this == e.target) {
                    var icon = $(this).children('i:first');
                    icon.toggleClass(openedClass + " " + closedClass);
                    $(this).children().children().toggle();
                }
            })
            branch.children().children().toggle();
        });
        //fire event from the dynamically added icon
        tree.find('.branch .indicator').each(function(){
            $(this).on('click', function () {
                $(this).closest('li').click();
            });
        });
        //fire event to open branch if the li contains an anchor instead of text
        tree.find('.branch>a').each(function () {
            $(this).on('click', function (e) {
                $(this).closest('li').click();
                e.preventDefault();
            });
        });
        //fire event to open branch if the li contains a button instead of text
        tree.find('.branch>button').each(function () {
            $(this).on('click', function (e) {
                $(this).closest('li').click();
                e.preventDefault();
            });
        });
    }
});


$( document ).ready(function() {
    // Left menu collapse
    $(document).on('click', '.button-menu-mobile', function(e) {
        e.preventDefault();
        $(document.body).toggleClass('sidebar-enable');
    });});

document.addEventListener("turbo:load", function() {
    'use strict';

    // Init ZeAdmin modules
    groupTypeMod.init();

    $('#tree').treed();

    // Metis menu init
    $('.side-nav').metisMenu();

    // Bootstrap Tooltip init
    $('[data-toggle="tooltip"]').tooltip()

    $('.select2').select2()

    // Boostrap select2 for customers
    $('.customer-select2').select2({
        ajax: {
            url: $(".customer-select2").data("url"),
            dataType: 'json',
            data: function(params) {
                return { search: params.term }
            },
            processResults: function (data, params) {
                return {
                    results: $.map(data, function(value, index){
                        return {id: value.id, text: value.firstname + " " + value.lastname};
                    })
                };
                cache: true
            }
        }
    });

    // Boostrap select2 for groups
    $('.group-select2').select2({
        ajax: {
            url: $(".group-select2").data("url"),
            dataType: 'json',
            data: function(params) {
                return { search: params.term }
            },
            processResults: function (data, params) {
                return {
                    results: $.map(data, function(value, index){
                        return {id: value.id, text: value.name};
                    })
                };
                cache: true
            }
        }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
})



var DapimProduct = {

    updatePreview: function () {

        $('.picture_file').change(function () {

            var reader, thumb;
            thumb = $(this).closest('tr').find('.thumb');
            reader = new FileReader();
            reader.onload = function (e) {
                var img;
                img = $('<img>');
                img.attr('src', e.target.result);
                thumb.html("");
                img.appendTo(thumb);
            };

            reader.readAsDataURL(this.files[0]);

        });
    },


    check_to_hide_or_show_product_add_link: function () {

        if ($('#product-pictures .nested-fields').length > 4) {
            $('#add-picture a').hide();
        } else {
            $('#add-picture a').show();
        }

    },


    aferCocoonInsert: function () {

        $('#product-pictures').bind('cocoon:after-insert', function () {
            DapimProduct.updatePreview();
            DapimProduct.check_to_hide_or_show_product_add_link();
        });

    },


    aferCocoonRemove: function () {

        $('#product-pictures').bind('cocoon:after-remove', function () {

            DapimProduct.check_to_hide_or_show_product_add_link();
        });

    }
};


document.addEventListener("turbo:load", function() {
    DapimProduct.updatePreview();
    DapimProduct.aferCocoonInsert();
    DapimProduct.aferCocoonRemove();
});
